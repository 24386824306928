import axios  from 'axios'
const axiosInstance = axios.create({
    // baseURL: 'https://nice.xiaozengzhang.net',
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    timeout: 15000,
    validateStatus(status) {
        return status >= 200 && status < 500;
    },
})

export default axiosInstance;