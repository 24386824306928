
















import { Component, Vue } from 'vue-property-decorator';
import { Download } from '@/lib/script/download'
import axios from '@/lib/script/axios'
import { getQS } from "@/lib/script/utils";
import { Route } from 'vue-router';
import { Toast } from 'vant'

@Component({
	name: 'Index',
  components: {
  },
})
export default class Index extends Vue {
	public imageLoadCount = 0
	public isUrl = false
	public gotoPage() {
		location.href = 'onewidget://profile_downloaded'
	}
	public created () {
		const qs = getQS()
		if(qs.down_load_url) {
			location.href = qs.down_load_url;
			this.isUrl = true;
		}
	}
	public mounted () {
		const ZXIMAGE = this.$refs.ZXIMAGE as HTMLImageElement;
		// ZXIMAGE.onload = () => {
		// 	console.log('target', this.imageLoadCount);
		// }
		ZXIMAGE.onerror = () => {
			this.imageLoadCount++;
			if(this.imageLoadCount < 20) {
				// ZXIMAGE.src = require('../assets/image1.png')
				ZXIMAGE.src = 'https://everygrowth-small.oss-cn-beijing.aliyuncs.com/manual/onenice/onewidget/h5/WechatIMG802.png'
			}
		}
	}
}
