

























import { Component, Vue } from 'vue-property-decorator';
import { Download } from '@/lib/script/download'
import axios from '@/lib/script/axios'
import { getQS } from "@/lib/script/utils";
import { Route } from 'vue-router';
import { Toast } from 'vant'

@Component({
  components: {
  },
})
export default class Home extends Vue {
	public url = ''
	public gotoPage() {
		// location.href="App-Prefs:root=General&path=ManagedConfigurationList"
		if(!this.url) {
			return Toast('请先下载描述文件')
		}
		if(this.url) {
			location.href = 'https://onewidget.onenice.tech/embedded2.mobileprovision'
		}
	}
	public get imgUrl() {
		const locale = this.$i18n.locale;
		return require(`../assets/${locale}.jpg`)
	}
	public async onClickDown() {
		const qs = getQS()
		if(!qs.theme_id) {
			return Toast('没有主题id')
		}
		if(!qs.appid) {
			return Toast('没有appid')
		}
		const res = await axios.post('/client/widget/theme/mobileconfig', {
			id: qs.theme_id,   // 主题id
			appid: qs.appid.split(','),   // 应用id
			lang: qs.lang,
		})
		const rdata = res.data;
		console.log('target res', res, qs);
		if(rdata.code === 0) {
			this.url = rdata.data;
			location.href=rdata.data
		}
	}
}
